/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import IcCheck from 'assets/ic_check.svg';
import IcAppstore from 'assets/ic_appstore.svg';
import IcPlaystore from 'assets/ic_playstore.svg';
import { auth } from 'utils/firebase';
import IcMenu from 'assets/ic_menu.svg';

import { API_CALLS, callAPI } from 'api';
import { DataContext } from 'utils/DataContext';
import Skeleton from 'react-loading-skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getAccessToken } from 'api/http-common';
import axios from 'axios';
import { TopBackground } from 'components/TopBackground';
import IcVerified from 'assets/ic_verified.svg';
import IcPowered from 'assets/powered_img.svg';
import { MusicList } from 'components/MusicList';
import { SELECTED_THEME, themeColors } from 'utils/theme';
import { FanfliqModal } from 'components/modal';
import HLSAudioPlayer from 'components/hls-audio-player';
import { Button, Dropdown, Modal, Spinner } from 'react-bootstrap';
import { media } from 'utils/media';

export function Album() {
  let { id } = useParams();

  const [list, setList] = useState([{}, {}, {}, {}, {}]);
  const [fliq, setFliq] = useState({});
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const audioRef = useRef(null);

  const [content, setContent] = useState({});
  const [country, setCountry] = useState('IN');
  const { notify, curAlbumId, setPlaylist, curPlayingId, myProfile } =
    useContext(DataContext);
  const [showWelcome, setShowWelcome] = useState(false);
  const [showError, setShowError] = useState(false);

  const [artist, setArtist] = useState({});
  const [loader, setLoader] = useState(false);
  const ref = useRef(null);

  const [showHeader, setShowHeader] = useState('none');

  useEffect(() => {
    if (ref.current) {
      console.log(ref.current.scrollHeight, ref.current.clientHeight);
      if (ref.current.scrollHeight > ref.current.clientHeight)
        setShowHeader('none');
      else setShowHeader('flex');
    }
  }, [ref]);

  const getGeoInfo = async () => {
    setLoader(true);
    try {
      const res = await axios.get('https://ipinfo.io?token=fb2f8544191fbf');
      if (res && res.data) {
        console.log(res);
        setCountry(res.data.country);
      }
      setLoader(false);
    } catch (e) {
      console.log('Cant get country');
      setLoader(false);
      setCountry();
    }
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  useEffect(() => {
    if (window.location.hash.includes('#buy')) {
      handleBuy();
      window.history.pushState(
        {},
        null,
        window.location.href.replace('#buy', ''),
      );
    } else loadContent();
  }, [country]);

  useEffect(() => {
    if (curAlbumId === id && content) setPlaylist(content.contentIds);
  }, [curAlbumId, content]);

  const handleScroll = () => {
    if (document.getElementById('top').scrollTop >= 100) setShowHeader('flex');
    else setShowHeader('none');
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);

  const getSocialIcon = (type = '') => {
    return require(`assets/${type}.svg`);
  };

  function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }

  useEffect(() => {
    if (content && artist.avatar) {
      if (searchParams.get('status') === 'success') {
        // eslint-disable-next-line no-restricted-globals
        history.pushState(null, '', location.href.split('?')[0]);
        setShowWelcome(true);
      } else if (
        searchParams.get('status') === 'failed' &&
        searchParams.get('error')
      ) {
        // eslint-disable-next-line no-restricted-globals
        history.pushState(null, '', location.href.split('?')[0]);
        setShowError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, artist]);

  const loadContent = async () => {
    try {
      const resp = await callAPI(API_CALLS.GET_CONTENT, id);
      setContent(resp);
      fetchArtistDetails(resp.artistUsername);
    } catch (e) {
      console.log(e);
      setContent({});
      const errResponse =
        (e && e?.response && e?.response.data && e?.response.data.message) ||
        (e && e?.message) ||
        'There was some issue while loading content.';
      notify(errResponse, 'error');
    }
  };

  const fetchArtistDetails = async id => {
    try {
      const response = await axios.get(
        `https://api.fanfliq.com/v1/users/details/${id}`,
      );
      setArtist(response.data);
    } catch (err) {
      notify('Error in loading data', 'error');
    }
  };

  useEffect(() => {
    if (content && content.id) {
      fetchContentList();
      fetchFliqs();
    }
  }, [content]);

  const fetchContentList = async () => {
    const headers = {
      Authorization: `Bearer ${getAccessToken()}`,
    };
    let query = '';
    query += `userId=${content.userId}&`;
    query += `albumId=${id}`;
    content.contentIds.forEach(id => (query += `&ids=${id}`));

    try {
      const response = await axios.get(
        `https://api.fanfliq.com/v1/content?${query}`,
        {
          headers: headers,
        },
      );
      setList(response.data.results);
    } catch (e) {
      if (e.response?.status === 401) goToLoginNormal();
      else {
        setList();
        const errResponse =
          (e && e?.response && e?.response.data && e?.response.data.message) ||
          (e && e?.message) ||
          'There was some issue while loading content.';
        notify(errResponse, 'error');
      }
    }
  };

  const fetchFliqs = async () => {
    const headers = {
      Authorization: `Bearer ${getAccessToken()}`,
    };
    let query = '';
    query += `userId=${content.userId}&`;
    query += `audioId=${id}`;

    try {
      const response = await axios.get(
        `https://api.fanfliq.com/v1/fliq?${query}`,
        {
          headers: headers,
        },
      );
      setFliq(response.data.results[0]);
    } catch (e) {
      if (e.response?.status === 401) goToLoginNormal();
      else {
        setList();
        const errResponse =
          (e && e?.response && e?.response.data && e?.response.data.message) ||
          (e && e?.message) ||
          'There was some issue while loading content.';
        notify(errResponse, 'error');
      }
    }
  };

  const handlePaypal = async () => {
    const headers = {
      Authorization: `Bearer ${getAccessToken()}`,
    };
    try {
      const res = await axios.post(
        `https://api.fanfliq.com/v1/order/create/paypal`,
        { contentId: id },
        {
          headers: headers,
        },
      );
      setLoader(false);

      if (res) {
        window.open(res.data.payLink, '_self');
      }
    } catch (e) {
      if (e.response?.status === 401) goToLoginNormal();
      else {
        setLoader(false);
        const errResponse =
          (e && e?.response && e?.response.data && e?.response.data.message) ||
          (e && e?.message) ||
          'There was some issue while initiating payment.';
        notify(errResponse, 'error');
      }
    }
  };

  const initiatePurchase = (env, source) => {
    let url =
      env === null || env === 'prod'
        ? 'https://api.fanfliq.com/v1/order/create/payu'
        : 'https://api.fanfliq.com/v1/order/create/payu';

    axios
      .post(
        url,
        {
          contentId: id,
          source: source ? source : 'mobile',
          env: env ? env : 'prod',
        },
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        },
      )
      .then(response => {
        if (response && response.status === 200) {
          let options = {
            ...response.data,
            redirect: true,
          };

          if (options.paymentLink) {
            window.location.href = options.paymentLink;
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleBuy = async () => {
    if (getAccessToken()) {
      setLoader(true);
      if (country !== 'IN') {
        handlePaypal();
      } else {
        initiatePurchase();
      }
    } else goToLogin();
  };

  const goToLogin = () => {
    let redirect = window.location.href;
    if (!redirect.includes('#buy')) redirect += '#buy';

    navigate(`/login?redirect=${redirect}`);
  };

  const goToLoginNormal = () => {
    let redirect = window.location.href;
    navigate(`/login?redirect=${redirect}`);
  };

  const handleLogout = async () => {
    setLoader(true);
    auth.signOut();
    await callAPI(API_CALLS.LOGOUT);
    setLoader(false);
    localStorage.clear();
    window.location.reload();
  };

  const [showMenu, setShowMenu] = useState(false);

  const renderMenuMobile = () => {
    return (
      <Modal
        className="modal-dialog-menu"
        style={{ margin: 0, width: '100%' }}
        show={showMenu}
        onHide={() => setShowMenu(false)}
      >
        <Modal.Body
          style={{ background: 'white', margin: 0, padding: '1.6rem' }}
        >
          <div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {!loader ? (
                getAccessToken() ? (
                  <Button
                    onClick={handleLogout}
                    className="primary-btn text-14"
                    style={{ width: '100%', marginTop: '3.2rem' }}
                  >
                    Logout
                  </Button>
                ) : (
                  <Button
                    onClick={goToLoginNormal}
                    className="primary-btn text-14"
                    style={{ width: '100%', marginTop: '3.2rem' }}
                  >
                    Login
                  </Button>
                )
              ) : (
                <div
                  className="primary-btn"
                  style={{ width: '100%', marginTop: '3.2rem' }}
                >
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const renderHeader = () => {
    return (
      <div className="w-596 header-bg">
        <div style={{ display: 'flex', alignItems: 'center', gap: '1.6rem' }}>
          <img referrerPolicy="no-referrer" src={artist.avatar} alt="avatar" />
          <div className="text">{artist.username}</div>
        </div>
        {getAccessToken() ? (
          <Dropdown placement="bottom-start" style={{ zIndex: 2 }}>
            <Dropdown.Toggle
              as="div"
              id="dropdown-custom-components"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <HeaderImage
                referrerPolicy="no-referrer"
                alt="profile-pic"
                src={myProfile?.avatar}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                borderRadius: '8px',
                padding: '1rem',
                background: 'white',
                maxWidth: '40rem',
              }}
            >
              <div>
                <div
                  className="text-14"
                  style={{
                    marginTop: '0.8rem',
                    fontSize: '1.4rem',
                    color: themeColors[SELECTED_THEME].primary,
                  }}
                >
                  {myProfile?.name}
                </div>
                {!loader ? (
                  <Button
                    onClick={handleLogout}
                    className="primary-btn text-14"
                    style={{ marginTop: '3.2rem', width: '20rem' }}
                  >
                    Logout
                  </Button>
                ) : (
                  <div
                    className="primary-btn"
                    style={{ marginTop: '3.2rem', width: '20rem' }}
                  >
                    <Spinner variant="light" />
                  </div>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <div
            className="text-14 txt-btn hide-in-mobile"
            style={{ marginRight: '1rem' }}
            onClick={() => goToLoginNormal()}
          >
            LOGIN
          </div>
        )}
        <Menu src={IcMenu} alt="menu" onClick={() => setShowMenu(true)} />
        {renderMenuMobile()}
      </div>
    );
  };

  const renderFliq = () => {
    return (
      <div className="w-508 fliq-card">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
            {artist?.avatar ? (
              <ImageSmall
                alt={'profile'}
                wrapperClassName="circle-image-3"
                placeholderSrc={artist?.thumbnail}
                src={artist?.avatar}
              />
            ) : (
              <ImageSkeletonSmall />
            )}
            <div className="text-12-small">
              {artist.name ? (
                artist.name
              ) : (
                <Skeleton style={{ flex: 1, width: '15rem' }} />
              )}
            </div>
            <img
              style={{ width: '1.4rem', height: '1.4rem' }}
              src={IcVerified}
              alt="verfied"
            />
          </div>
          <div className="text-10-small">
            {fliq.createdTxt ? (
              fliq.createdTxt
            ) : (
              <Skeleton style={{ flex: 1, width: '1rem' }} />
            )}
          </div>
        </div>
        <div style={{ marginTop: '1.6rem' }} className="text-12-small">
          {fliq.topic ? (
            fliq.topic
          ) : (
            <Skeleton style={{ flex: 1, width: '14rem' }} />
          )}
        </div>
        <div style={{ marginTop: '1.6rem' }} className="black-card">
          {fliq?.coverArt ? (
            <ImageCover
              alt={'profile'}
              wrapperClassName="round-image-9"
              placeholderSrc={artist?.coverArt}
              src={fliq?.coverArt}
            />
          ) : (
            <ImageSkeletonCover />
          )}
          <div
            className="text-12-small"
            style={{
              marginTop: '0.8rem',
              lineHeight: '2.4rem',
              fontWeight: 800,
              textAlign: 'center',
            }}
          >
            {fliq.audioTitle ? (
              fliq.audioTitle
            ) : (
              <Skeleton style={{ flex: 1, width: '15rem' }} />
            )}
          </div>
          <div
            className="text-12-small"
            style={{
              marginTop: '0.8rem',
              lineHeight: '4rem',
              fontWeight: 600,
              fontSize: '1.6rem',
              textAlign: 'center',
            }}
          >
            {content.price ? (
              country === 'IN' ? (
                `₹ ${content.price}`
              ) : (
                `USD ${content.priceInUsd}`
              )
            ) : (
              <Skeleton style={{ flex: 1, width: '15rem' }} />
            )}
          </div>
          {content.haveAccess ? (
            <div
              style={{
                marginTop: '1.6rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div className="box-grey">
                <img style={{ width: '3.2rem' }} src={IcCheck} alt="check" />
                <div
                  className="text-12"
                  style={{ color: themeColors[SELECTED_THEME].textPrimary }}
                >
                  You already have access to this.
                </div>
              </div>
              <div
                className="text-16"
                style={{
                  marginTop: '1.6rem',
                  fontSize: '1.5rem',
                  color: themeColors[SELECTED_THEME].textPrimary,
                  fontWeight: 400,
                }}
              >
                Download FanFliQ for Fans and use same login method.
              </div>
              <div
                style={{ display: 'flex', marginTop: '1.6rem' }}
                className="gap-16px hover-link-ff"
              >
                <a href="https://play.google.com/store/apps/details?id=apps.fliqmedia.fanfliq">
                  <img
                    className="store-btn"
                    src={IcPlaystore}
                    alt="playstore"
                  />
                </a>
                <a href="https://apps.apple.com/us/app/fanfliq-for-fans/id6497407785">
                  <img className="store-btn" src={IcAppstore} alt="appstore" />
                </a>
              </div>
            </div>
          ) : fliq.status ? (
            fliq.status === 'active' ? (
              <div
                className="round-btn-red text-12-small hover-link-ff"
                style={{ marginTop: '1.6rem', textAlign: 'center' }}
                onClick={handleBuy}
              >
                {loader ? <Spinner /> : 'GET ACCESS NOW'}
              </div>
            ) : (
              <div className="box-grey">
                <div
                  className="text-12"
                  style={{
                    color: themeColors[SELECTED_THEME].textPrimary,
                    textAlign: 'center',
                  }}
                >
                  🎉 All 60 slots are now filled! 🎶 Thanks for the amazing
                  support! <br />
                  Stay tuned for more! 🚀✨
                </div>
              </div>
            )
          ) : (
            <Skeleton style={{ flex: 1, width: '15rem' }} />
          )}
        </div>
      </div>
    );
  };

  const renderSongs = () => {
    return (
      <div className="w-508 songs-list-bg">
        <MusicList list={list} />
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          zIndex: 10,
          justifyContent: 'center',
          position: 'fixed',
          top: 20,
          left: 0,
          right: 0,
          margin: '0 10px 0 10px',
          display: 'flex',
        }}
      >
        {renderHeader()}
      </div>
      <div
        style={{ overflow: 'hidden', height: '100vh' }}
        className="margin-head"
      >
        <TopBackground content={{ ...artist, cover: artist?.avatar }} />
        <div
          id="top"
          ref={ref}
          style={{
            overflow: 'scroll',
            position: 'relative',
            height: '100vh',
          }}
        >
          <div
            className="padding-56"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                zIndex: 3,
                display: 'flex',
                overflow: 'scroll',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {artist?.avatar ? (
                <Image
                  alt={'profile'}
                  wrapperClassName="circle-image-9"
                  placeholderSrc={artist?.thumbnail}
                  src={artist?.avatar}
                />
              ) : (
                <ImageSkeleton />
              )}
              <div className="text-20" style={{ marginTop: '0.8rem' }}>
                {artist.name ? (
                  artist.name
                ) : (
                  <Skeleton style={{ flex: 1, width: '15rem' }} />
                )}
              </div>
              <div
                style={{ display: 'flex', gap: '1.6rem', marginTop: '1.6rem' }}
              >
                {artist?.socials?.map((social, index) => (
                  <img
                    key={index}
                    onClick={() => openInNewTab(social.url)}
                    src={getSocialIcon(social.type)}
                    alt={social.type}
                    className="filter-hover"
                    style={{ height: '2.4rem', width: '2.4rem' }}
                  />
                ))}
              </div>
              {fliq ? (
                <div className="w-508" style={{ marginTop: '2.4rem' }}>
                  {renderFliq()}
                </div>
              ) : (
                <></>
              )}
              <div className="w-508" style={{ marginTop: '2.4rem' }}>
                {renderSongs()}
              </div>
              <div
                style={{
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  pointerEvents: 'none',
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 'auto',
                }}
              >
                <img
                  src={IcPowered}
                  alt="powered"
                  style={{
                    marginBottom: '4rem',
                  }}
                />
              </div>
            </div>
          </div>
          <FanfliqModal
            show={showWelcome}
            dialogClass="dialog-small"
            title={
              <div>
                <img src={IcCheck} alt="check" />
                <div
                  className="text-20 bold"
                  style={{
                    color: themeColors[SELECTED_THEME].textPrimary,
                    fontSize: '2rem',
                  }}
                >
                  Access available in the App NOW!!
                </div>
              </div>
            }
            subtitle={
              <div
                className="text-20"
                style={{
                  color: themeColors[SELECTED_THEME].textPrimary,
                  fontWeight: 400,
                }}
              >
                Download FanFliQ for Fans and use same login method.
              </div>
            }
            primaryBtn={
              <div
                style={{ display: 'flex' }}
                className="gap-16px hover-link-ff"
              >
                <a href="https://play.google.com/store/apps/details?id=apps.fliqmedia.fanfliq">
                  <img
                    className="store-btn"
                    src={IcPlaystore}
                    alt="playstore"
                  />
                </a>
                <a href="https://apps.apple.com/us/app/fanfliq-for-fans/id6497407785">
                  <img className="store-btn" src={IcAppstore} alt="appstore" />
                </a>
              </div>
            }
          />
          <FanfliqModal
            show={showError}
            dialogClass="dialog-small"
            title={
              <div>
                <div
                  className="text-20 bold"
                  style={{ color: themeColors[SELECTED_THEME].textPrimary }}
                >
                  Oh no! Something went wrong!
                </div>
              </div>
            }
            subtitle={
              <div
                className="text-20"
                style={{
                  color: themeColors[SELECTED_THEME].textPrimary,
                  fontWeight: 400,
                }}
              >
                Sorry, we had an issue confirming your payment.
                <br /> Please try again.
              </div>
            }
            primaryBtn={
              <div
                className="round-btn-red text-12-small hover-link-ff"
                style={{ textAlign: 'center', width: '50%' }}
                onClick={handleBuy}
              >
                TRY AGAIN
              </div>
            }
            secondaryBtn={'CANCEL'}
            handleSecondaryBtn={() => setShowError(false)}
          />
          {curPlayingId ? (
            <HLSAudioPlayer
              audioRef={audioRef}
              goToLogin={goToLogin}
              setShowDownloadApp={() => setShowWelcome(true)}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

const Image = styled(LazyLoadImage)`
  width: 9.6rem;
  height: 9.6rem;
  border-radius: 100%;
`;

const HeaderImage = styled.img`
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 100%;
  cursor: pointer;
  ${media.mobile`display: none`};
  ${media.small`display: none`};
  ${media.medium`display: flex`};
`;

const ImageSkeleton = styled(Skeleton)`
  width: 9.6rem;
  height: 9.6rem;
  border-radius: 100%;
`;

const ImageSmall = styled(LazyLoadImage)`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 100%;
`;

const ImageSkeletonSmall = styled(Skeleton)`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 100%;
`;

const ImageCover = styled(LazyLoadImage)`
  width: 9.6rem;
  height: 9.6rem;
  border-radius: 0.4rem;
`;

const ImageSkeletonCover = styled(Skeleton)`
  width: 9.6rem;
  height: 9.6rem;
  border-radius: 0.4rem;
`;

const Menu = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 6.4rem;
  margin-right: 1rem;
  ${media.mobile`display: flex`};
  ${media.small`display: flex`};
  ${media.medium`display: none`};
`;
